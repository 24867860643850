import React, { useState, useEffect } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import classes from './toast.context.module.scss';

export const toastContext = React.createContext({});

export const ToastProvider = ({ children }) => {
  const [toastQueue, setToastQueue] = useState([]);

  const checkForValidToasts = (toast) => {
    return typeof toast.show === 'undefined' || toast.show === true;
  };

  const addToast = (toastConfig) => {
    setToastQueue((prevQueue) => {
      // to avoid leaking memory, purge state array if all toasts in it are hidden
      if (prevQueue.filter(checkForValidToasts).length === 0) {
        return [toastConfig];
      } else {
        return [...prevQueue, toastConfig];
      }
    });
  };

  // toasts must be hidden rather than removed from the state array to avoid Bootstrap losing track
  // of which Toasts still need to be auto-hidden
  const hideToast = (indexToHide) => {
    setToastQueue((prevQueue) =>
      prevQueue.map((toast, index) => {
        if (index === indexToHide) {
          toast.show = false;
        }
        return toast;
      })
    );
  };

  const displayContent = (withHtml, body) => {
    if (withHtml) {
      return <div dangerouslySetInnerHTML={{ __html: body }}></div>;
    } else {
      return body;
    }
  };

  return (
    <toastContext.Provider value={{ addToast }}>
      <>
        <div key="toastSibling">{children}</div>
        <ToastContainer className={classes.toastContainer}>
          {toastQueue.map(({ header, body, withHtml, delay = 5000, autohide = true, timestamp, show = true, type = 'info' }, index) => {
            return (
              <Toast
                className={classes.toastInnerContainer}
                key={index}
                show={show}
                delay={delay}
                autohide={autohide}
                onClose={(e) => {
                  hideToast(index);
                }}
              >
                {header && <Toast.Header>{header}</Toast.Header>}
                {body && (
                  <Toast.Body data-cy="toast" className={`${classes.toastBody} ${classes[type]}`}>
                    {displayContent(withHtml, body)}
                  </Toast.Body>
                )}
              </Toast>
            );
          })}
        </ToastContainer>
      </>
    </toastContext.Provider>
  );
};

export const ToastConsumer = toastContext.Consumer;
